import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p2 from 'assests/Photos/ClassAssembly/2023/4C/1.jpg';
import p1 from 'assests/Photos/ClassAssembly/2023/4C/2.jpg';
import p3 from 'assests/Photos/ClassAssembly/2023/4C/3.jpg';
import p4 from 'assests/Photos/ClassAssembly/2023/4C/4.jpg';


import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly2023';

const ClassAssembly4C2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
        src: p3,
        source: p3,
        rows: 1.2,
        cols: 2,
    },
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    }, 
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
  },
    {
        src: p2,
        source:p2,
        rows: 1,
        cols: 2,
      }, 
     
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Gratitude to our Teachers
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
 Class: 4C   Date: 4 September 2023 & 6 September 2023

        </Typography>

        <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Great Teachers empathise with kids, respect them and believe that each one has something special
that can be built up on.” Ann Lieberman”

<br/><br/>
        </Typography>
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of Class 4C presented their assembly on the topic, ‘Gratitude to our Teachers’ on Monday,
        4 September 2023 and Wednesday, 6 September 2023. The assembly was a heart-warming tribute to
        the educators, filled with gratitude, admiration and respect. The event began with a profound thought,
        setting the tone for the day, emphasising the transformative power of educators. The thought was
        followed by a speech, recognising the exceptional role that teachers play in our lives. It emphasised how
        teachers are the guiding lights, shaping the futures with their knowledge, education and care. Students
        were reminded of the profound impact educators have on their personal growth and development.
        Students also sang a melodious song which conveyed the importance of teachers and dedicated it to
        their teachers.
        <br></br>
        The highlight of the assembly was a reflective skit that portrayed the life journey of students who wanted to felicitate and reward their teacher whom they considered as their role model, but in turn the teacher urged them to educate a child who is deprived of education and that he will consider as true token of gratitude towards him. The assembly concluded on a vibrant note with an energetic dance performance.
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Teachers who love teaching teach children to love learning”

<br/>
        </Typography>

    <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              /> 
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly4C2023;